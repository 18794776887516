import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from 'components/tag';
import IphoneXSpace from 'components/iphonex-space';
import './style.less';

export const EmbedLayout = (props) => {
  const {
    onScroll,
    className,
    header,
    footer,
    ariaHidden,
    canLayoutScroll,
    children,
    ...restProps
  } = props;
  const handleMainScroll = (e) => {
    if (props.onScroll) {
      props.onScroll(e);
    }
  };
  return (
    <Tag.div
      aria-hidden={ariaHidden}
      className={classNames('embed_layout', className)}
      {...restProps}
    >
      {header && <Tag.div className="embed_layout_header">{header}</Tag.div>}
      <Tag.div
        className="embed_layout_main"
        // scrollY={canLayoutScroll}
        onScroll={handleMainScroll}
        enableBackToTop
      >
        {children}
      </Tag.div>
      {footer && (
        <Tag.div className="embed_layout_footer">{footer}</Tag.div>
      )}
      {footer && <IphoneXSpace />}
    </Tag.div>
  );
};

EmbedLayout.defaultProps = {
  className: '',
  canLayoutScroll: true,
};

EmbedLayout.propTypes = {
  className: PropTypes.string,
  onScroll: PropTypes.func,
  ariaHidden: PropTypes.bool,
  canLayoutScroll: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};
