import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  href_blog_archive as hrefBlogArchive,
  href_faqs_more as hrefFaqsMore,
  href_home as hrefHome,
  href_roadmap as hrefRoadmap,
  href_new_post as hrefNewPost,
  hrefPostList,
  href_profile as hrefProfile,
} from 'components/href-helper';
import {
  TcIconExchange,
  TcIconPen,
  TcIconRealExchange,
} from 'components/IconSystem';
import { getProductId, sessionStorage } from 'components/util';
import Icon from 'components/icon';
import { get_api_products_menu as getApiProductsMenu } from 'components/api';
import { TABS, FOOTER_TARGET } from './constants';

import './style.less';
import loginChecker from 'components/util/login-checker';
import Route from 'components/util/route';
import ariaAutoFocus from 'components/util/aria/auto-focus';
import { pageInfo } from 'components/util/page-info';
import { isAndroid } from 'components/env';

class FooterBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showType: 0,
      hasFaq: false,
      hasRoadmap: false,
      hasBlog: false,
      isInit: false,
    };
  }
  componentDidMount() {
    this.init();
  }

  init() {
    const productId = getProductId();
    getApiProductsMenu(productId)
      .then(({ data }) => {
        this.setState({
          showType: data.display_mode,
          hasFaq: data.faq,
          hasRoadmap: data.roadmap,
          hasBlog: data.blog,
          isInit: true,
        });
      })
      .catch(() => {
        this.setState({
          isInit: true,
        });
      });
  }

    /** 自动计算当前是哪个Tab，减少参数传递 */
    getTarget = () => {
      const pathnames = location.pathname.match(/^\/\D+\/\d+\/([\w_-]+)/i);
      if (!Array.isArray(pathnames) || !FOOTER_TARGET[pathnames[1]]) {
        return TABS.HOME;
      }
      return FOOTER_TARGET[pathnames[1]];
    }

    render() {
      const { props, state } = this;
      const target = this.getTarget();
      if (!state.isInit) {
        return null;
      }
      return (
        <>
          {state.showType ? (
            <LargeFootBar target={target} allowTourist={props.allowTourist} />
          ) : (
            <GeneralFootBar
              target={target}
              hasFaq={state.hasFaq}
              hasRoadmap={state.hasRoadmap}
              hasBlog={state.hasBlog}
              allowTourist={props.allowTourist}
            />
          )}
        </>
      );
    }
}

FooterBar.TABS = TABS;

FooterBar.defaultProps = {
  target: '',
};

FooterBar.propTypes = {
  target: PropTypes.string,
  allowTourist: PropTypes.bool,
};

const GeneralFootBar = (props) => {
  const isHome = props.target === FooterBar.TABS.HOME;
  const isFaq = props.target === FooterBar.TABS.FAQ;
  const isRoadmap = props.target === FooterBar.TABS.ROADMAP;
  const isBlog = props.target === FooterBar.TABS.BLOG;
  const isMine = props.target === FooterBar.TABS.MINE;

  const activeRef = useRef(null);
  const resetTabFocus = useRef(false);

  const handleMineClick = () => {
    loginChecker.check().then((res) => {
      const href = hrefProfile(res.data.user_id, props.allowTourist);
      Route.push(href);
    });
  };

  // 聚焦当前选中的tab
  const focusAriaSelectedItem = () => {
    if (resetTabFocus.current) {
      return;
    }

    resetTabFocus.current = true;
    const isInitSession = sessionStorage.getItem('isInitSession');
    // 首次进入页面，聚焦到顶部即可
    if (!isInitSession) {
      sessionStorage.setItem('isInitSession', true);
      ariaAutoFocus();
      return;
    }

    // 聚焦到当前选中的tab
    try {
      activeRef.current.focus();
      activeRef.current.setAttribute('tabindex', '0');
    } catch (e) {
      // 聚焦失败，聚焦到顶部即可
      ariaAutoFocus();
    }
  };

  useEffect(() => {
    if (!pageInfo.inMobileQQ || !isAndroid()) {
      setTimeout(focusAriaSelectedItem, 400);
      return;
    }

    // 手Q环境需要延迟等待X5内核自动聚焦后切换焦点
    const initFocusTimeout = setTimeout(focusAriaSelectedItem, 1000);
    window.addEventListener('load', () => {
      clearTimeout(initFocusTimeout);
      setTimeout(focusAriaSelectedItem, 1000);
    });
  }, []);

  return (
    <div className="footer_bar pd">
      <div className="wrap" role="tablist">
        <div className={classnames('go_tab', { 'go_tab--active': isHome })}>
          <a ref={isHome ? activeRef : null} href={hrefHome()} role="tab" aria-selected={isHome}>
            <Icon type={isHome ? 'index-dark' : 'index-light'} />
            <span className="text">首页</span>
          </a>
        </div>
        {
          props.hasRoadmap && (
            <div className={classnames('go_tab', { 'go_tab--active': isRoadmap })}>
              <a href={hrefRoadmap()} ref={isRoadmap ? activeRef : null} role="tab" aria-selected={isRoadmap}>
                <Icon type={isRoadmap ? 'roadmap-dark' : 'roadmap-light'} />
                <span className="text">功能投票</span>
              </a>
            </div>
          )
        }
        {
          props.hasFaq && (
            <div className={classnames('go_tab', { 'go_tab--active': isFaq })}>
              <a href={hrefFaqsMore()} ref={isFaq ? activeRef : null} role="tab" aria-selected={isFaq}>
                <Icon type={isFaq ? 'question-dark' : 'question-light'} />
                <span className="text">常见问题</span>
              </a>
            </div>
          )
        }
        {
          props.hasBlog && (
            <div className={classnames('go_tab', { 'go_tab--active': isBlog })}>
              <a href={hrefBlogArchive()} ref={isBlog ? activeRef : null} role="tab" aria-selected={isBlog}>
                <Icon type={isBlog ? 'blog-dark' : 'blog-light'} />
                <span className="text">团队博客</span>
              </a>
            </div>
          )
        }
        <div className={classnames('go_tab', { 'go_tab--active': isMine })}>
          <button href="javascript:;" ref={isMine ? activeRef : null} onClick={handleMineClick} role="tab" aria-selected={isMine}>
            <Icon type={isMine ? 'mine-dark' : 'mine-light'} />
            <span className="text">我的</span>
          </button>
        </div>
      </div>
    </div>
  );
};

GeneralFootBar.propTypes = {
  target: PropTypes.string,
  userId: PropTypes.number,
  hasFaq: PropTypes.bool,
  hasRoadmap: PropTypes.bool,
  hasBlog: PropTypes.bool,
  allowTourist: PropTypes.bool,
};


const LargeFootBar = (props) => {
  const handleNewPostClick = () => {
    loginChecker.check().then((res) => {
      const href = hrefNewPost(res.data.user_id, props.allowTourist);
      Route.push(href);
    });
  };
  return (
    <div className="footer_bar">
      <div className="wrap">

        <div className={classnames('go_tab go_index', { 'go_tab--active': props.target === FooterBar.TABS.HOME })}>
          <a href={hrefHome()}>
            <Icon type={props.target === FooterBar.TABS.HOME ? 'index-dark' : 'index-light'} />
            <span className="text">看一看</span>
          </a>
        </div>
        <div className="elliptical_btn" onClick={handleNewPostClick} role="button" tabIndex={0} onKeyDown={handleNewPostClick}>
          <span className="btn_shape">
            <TcIconPen />
          </span>
        </div>
        <div className="go_tab go_tucao">
          <a href={hrefPostList()}>
            {props.target === FooterBar.TABS.POST ? <TcIconRealExchange /> : <TcIconExchange />}
            <span className="text">吐个槽</span>
          </a>
        </div>
      </div>
    </div>
  );
};
LargeFootBar.propTypes = {
  target: PropTypes.string,
  hasFaq: PropTypes.bool,
  hasBlog: PropTypes.bool,
  allowTourist: PropTypes.bool,
};

export { FooterBar };
