import Page from 'components/constants/page';
/** 目前的tab高亮 */
export const TABS = {
  HOME: 'home',
  FAQ: 'faq',
  ROADMAP: 'roadmap',
  BLOG: 'blog',
  MINE: 'mine',
  POST: 'post',
};

/** NOTE 底部tab高亮的配置， 新页面记得配 */
export const FOOTER_TARGET = {
  general: TABS.HOME,
  roadmap: TABS.ROADMAP,
  blog: TABS.BLOG,
  'blog-archive': TABS.BLOG,
  faqs: TABS.FAQ,
  'faqs-category': TABS.FAQ,
  'faqs-list': TABS.FAQ,
  'faqs-more': TABS.FAQ,
  large: TABS.HOME,
  'post-list': TABS.POST,
  profile: TABS.MINE,
  team: TABS.BLOG,
};
// 小程序路由映射
export const FOOTER_TARGET_WEAPP = {
  [Page.PAGE_INDEX]: TABS.HOME,
  [Page.PAGE_ROADMAP]: TABS.ROADMAP,
  [Page.PAGE_FAQ_LIST]: TABS.FAQ,
  [Page.PAGE_BLOG_LIST]: TABS.BLOG,
  [Page.PAGE_PROFILE]: TABS.MINE,
};
