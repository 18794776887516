interface FocusElement extends Element {
  focus?: () => void;
}
/**
 * 使用auto-focus 这个类标记页面聚焦点
 * 避免iOS中 voiceover 页面翻页时自动搜寻聚焦点
 */
export default function ariaAutoFocus() {
  // 由于部分节点受接口请求影响，并且voiceover会在2秒找不到焦点自动聚焦顶部
  const duration = 400;
  let count = 5;

  // 小程序会自动切换焦点到顶部 不需要焦点控制
  if (process.env.TARO_ENV === 'weapp') return;

  const timer = setInterval(() => {
    if (count === 0) {
      clearInterval(timer);
      return;
    }
    count = count + 1;
    const focusElement: FocusElement = document.getElementsByClassName('auto-focus')[0];
    if (!focusElement) return;
    clearInterval(timer);
    focusElement.setAttribute('tabindex', '0');

    if (focusElement.focus) {
      focusElement.focus();
    }
  }, duration);
}
